/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import Navigation from "./navigation"
import AppContext from "./AppContext"
import { Component } from "react"
import Footer from "./footer"

class LayoutComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpened: false,
      error: false,
      setModalState: this.setModalState,
      setErrorState: this.setErrorState,
    }
  }

  setModalState = state => {
    this.setState({ modalOpened: state })
  }

  setErrorState = state => {
    this.setState({ error: state })
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <AppContext.Consumer>
          {({ modalOpened, error, setModalState, setErrorState }) => (
            <Navigation
              modalOpened={modalOpened}
              error={error}
              setModalState={setModalState}
              setErrorState={setErrorState}
            />
          )}
        </AppContext.Consumer>
        <main
          className={
            "w-full h-full " + (this.props.spacing ? "space-y-16" : "")
          }
        >
          {this.props.children}
        </main>
        <Footer />
      </AppContext.Provider>
    )
  }
}

export default LayoutComp
