import React, { Component } from "react"
import { Link } from "gatsby"
class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { verticalNav: props.verticalNav }
  }

  handleClick = () => {
    if (this.state.verticalNav) {
      this.props.onClick()
    }
  }

  render() {
    return (
      <ul>
        <li>
          <Link
            className="cursor-pointer"
            onClick={this.handleClick}
            to="/#home"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            className="cursor-pointer"
            onClick={this.handleClick}
            to="/#uebermich"
          >
            Über mich
          </Link>
        </li>
        <li>
          <Link
            className="cursor-pointer"
            onClick={this.handleClick}
            to="/#services"
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            className="cursor-pointer"
            onClick={this.handleClick}
            to="/#kontakt"
          >
            Kontakt
          </Link>
        </li>
        <li>
          <Link
            className="cursor-pointer"
            onClick={this.handleClick}
            to="/impressum"
          >
            Impressum
          </Link>
        </li>
      </ul>
    )
  }
}

export default NavigationList
