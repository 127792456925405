import React, { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import NavigationList from "./navigationList"
import "./../styles/nav.css"

class Navigation extends Component {
  state = { isActive: false }

  handleClick = () => {
    console.log("click")
    this.closeModalIfOpened()
    this.setState({ isActive: !this.state.isActive })

    var root = document.documentElement
    if (!this.state.isActive) {
      root.className = "prevent-scrolling"
    } else {
      root.className = ""
    }
  }

  closeModalIfOpened = () => {
    if (this.props.modalOpened) {
      this.props.setModalState(false)
      this.props.setErrorState(false)
    }
  }

  render() {
    return (
      <nav
        className={`flex flex-col fixed top-0 z-50 w-full text-white lg:bg-gray-1 lg:bg-opacity-90 ${
          this.state.isActive ? "collapsed" : ""
        }`}
      >
        <div
          id="nav-headline"
          className="container max-w-screen-lg xl:max-w-screen-xl flex h-16 mx-auto px-6 py-4 justify-between bg-gray-1 bg-opacity-90 lg:bg-transparent"
        >
          <AnchorLink
            to="/#home"
            className="flex items-center text-white nav-headline"
            stripHash
          >
            <img
              src="/logo.svg"
              className="nav-logo inline-block mr-3 h-full cursor-pointer w-9"
              alt="Logo Smolarczyk"
            />
            <p className="inline-block text-xl">Smolarczyk</p>
          </AnchorLink>
          <div className="nav-horizontal-items hidden md:flex items-center md:w-4/6 lg:w-3/6 xl:w-5/12">
            <NavigationList
              verticalNav="true"
              onClick={this.closeModalIfOpened}
            />
          </div>
          <button
            aria-label="Navigation öffnen"
            className={`flex items-center hamburger hamburger--squeeze js-hamburger disable-tap-highlight focus:outline-none ${
              this.state.isActive ? "is-active" : ""
            }`}
            onClick={this.handleClick}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </button>
        </div>
        <div className="flex h-screen items-center nav-vertical-items bg-opacity-95 bg-blue-1">
          <NavigationList onClick={this.handleClick} verticalNav="true" />
        </div>
      </nav>
    )
  }
}

export default Navigation
