import React, { Component } from "react"

import { AnchorLink } from "gatsby-plugin-anchor-links"
class Footer extends Component {
  render() {
    return (
      <div className="bg-gray-1">
        <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 pb-16 pt-5  text-white">
          <div className="text-center h-16 px-6 py-4 mb-3 flex items-center justify-center">
            <img
              src="/logo.svg"
              className="nav-logo inline-block mr-3 h-full w-9"
              alt="Logo Smolarczyk"
            />
            <p className="inline-block text-xl">Smolarczyk</p>
          </div>
          <div className="grid grid-cols-1 gap-7 sm:grid-cols-2 md:grid-cols-3">
            <div>
              <p className="text-2xl mb-2 font-bold tracking-wide">Kontakt</p>
              <ul className="text-base space-y-2.5">
                <li>
                  <p>
                    Eduard Smolarczyk
                    <br />
                    Unter den Krohgärten 20
                    <br />
                    35288 Wohratal
                  </p>
                </li>
                <li>
                  <a href="tel:+49 151 50 583 582">+49 151 50 583 582</a>
                </li>
                <li>
                  <a href="mailto:mail@e-smolarczyk.de">mail@e-smolarczyk.de</a>
                </li>
              </ul>
            </div>
            <div>
              <p className="text-2xl mb-2 font-bold tracking-wide">Services</p>
              <ul className="text-base space-y-2.5">
                <li>
                  <AnchorLink to="/balkone" className="hover:underline">
                    Balkone & Terrassen
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/ueberdachungen" className="hover:underline">
                    Überdachungen
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/tore" className="hover:underline">
                    Tore & Zäune
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/treppen" className="hover:underline">
                    Treppen
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/gelaender" className="hover:underline">
                    Geländer
                  </AnchorLink>
                </li>
              </ul>
            </div>
            <div>
              <p className="text-2xl mb-2 font-bold tracking-wide">
                Rechtliches
              </p>
              <ul className="text-base space-y-2.5">
                <li>
                  <AnchorLink to="/datenschutz" className="hover:underline">
                    Datenschutz
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/impressum" className="hover:underline">
                    Impressum
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
