import React from "react"

const AppContext = React.createContext({
  modalOpened: false,
  error: false,
  setModalState: () => {},
  setErrorState: () => {},
})

export default AppContext
